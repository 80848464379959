#sidebar_r div {
  padding: 1px;
}
#sidebar_btn {
  position: absolute;
  top: 5px;
  right: 5px;
}
@media screen and (min-width: 600px) {
  #sidebar_r div {
    max-width: 300px;
  }
}
